.accordion{
    &-header{
      position: relative;
      padding: 30px 0;
      border-bottom: 1px $white solid;
        &-title{
          padding-right: 100px;
            .h-2{
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
            }
            @include d(1200){
              padding-right: 90px;
            }
            @include d(480){
              padding-right: 60px;
            }
        }
        &-btn{
          position: absolute;
          width: 69px;
          height: 69px;
          border-radius: 50%;
          top: 42px;
          right: 0;
          background-color: $blue;
          transform: rotate(90deg);
          transition: transform .4s ease;
          cursor: pointer;
            svg{
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%,-50%);
              width: 26px;
              height: 15px;
              pointer-events: none;
            }
            path{
              fill: $white;
            }
            @include d(1300){
              top: 30px;
            }
            @include d(991){
              width: 58px;
              height: 58px;
            }
            @include d(480){
              width: 52px;
              height: 52px;
              top: 21px;
            }
        }
    }
    &-content{
      padding: 50px 0;
      display: none;
        &-description{
          max-width: 590px;
            p:not(:last-child){
              margin-bottom: 20px;
            }
        }
    }
    &-projects{
      display: flex;
      flex-wrap: wrap;
      margin: 0 -47px;
      padding-top: 90px;
        &-box{
          width: 50%;
          padding: 0 47px;
            @include d(1300){
              padding: 0 30px;
            }
            @include d(767){
              padding: 0;
              width: 100%;
            }
        }
        @include d(1300){
          margin: 0 -30px;
        }
        @include d(767){
          margin: 0;
        }
    }
    &.active{
      .accordion{
        &-content{
          display: block;
        }
        &-header{
          &-btn{
            transform: rotate(270deg);
          }
        }
      }
    }
}