.hero{
  padding: 255px 0 216px;
  overflow: hidden;
    &-content{
      position: relative;
      z-index: 1;
        &-title{
          padding-top: 31px;
        }
    }
    &-detail{
      max-width: 310px;
      margin-left: auto;
      padding-top: 54px;
      position: relative;
      z-index: 1;
        @include d(991){
          max-width: 100%;
        }
    }
    &-offices{
        &-title{
          @extend .font-title-medium;
          font-size: 18px;
        }
        &-list{
          padding-top: 8px;
          overflow: hidden;
            [data-country="uae"]{
              text-transform: uppercase;
            }
        }
    }
    &-office{
        &-title{
          display: inline-block;
          position: relative;
          text-transform: capitalize;
            &:not(:last-child){
              padding-right: 27px;
                &:before{
                content: '';
                position: absolute;
                right: 9px;
                top: 8px;
                background-color: currentColor;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                pointer-events: none;
              }
            }
        }
    }
    &-carousel{
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
        &:before{
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0,0,0,.42);
          z-index: 1;
          pointer-events: none;
        }
        &-slide{
          height: 992px;
            img{
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
              pointer-events: none;
              will-change: transform;
              transform: scale(1.2);
              transition: transform 2s ease;
            }
            @include d(1700){
              height: 959px;
            }
            @include d(1440){
              height: 836px;
            }
            @include d(1300){
              height: 738px;
            }
            @include d(1200){
              height: 706px;
            }
        }
        @include d(991){
          & div{
            height: 100%;
          }
        }
        .slick-current{
          img{
            transform: scale(1);
          }
        }
    }
    @include d(1500){
      padding: 220px 0 200px;
    }
    @include d(1440){
      padding: 210px 0 170px;
    }
    @include d(1300){
      padding: 193px 0 110px;
    }
    @include d(767){
      padding: 190px 0 150px;
    }
    @include d(575){
      padding: 190px 0 120px;
    }
}