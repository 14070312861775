.offices{
  padding: 127px 0 0;
  margin-bottom: 127px;
    &-list{
      display: flex;
      flex-wrap: wrap;
      padding-left: 24px;
        &-box{
          width: 50%;
          padding-bottom: 40px;
          margin-bottom: 52px;
            &:nth-child(even){
              padding-left: 35px;
                @include d(1300){
                  padding-left: 30px;
                }
                @include d(767){
                  padding-left: 0;
                }
            }
            &:nth-child(odd){
              padding-right: 35px;
                @include d(1300){
                  padding-right: 30px;
                }
                @include d(767){
                  padding-right: 0;
                }
            }
            &:not(:last-child):not(:nth-last-child(2)){
              border-bottom: 1px $white solid;
            }
            @include d(767){
              width: 100%;
            }
        }
        .office{
            &-city{
              text-transform: uppercase;
            }
            &-address{
              margin: 0 0 0 auto;
              max-width: 205px;
                @include d(1200){
                  max-width: 100%;
                  margin-top: 20px;
                }
            }
            &-email{
              display: block;
              max-width: 205px;
              margin-left: auto;
              margin-top: 21px;
                @include d(1200){
                  max-width: 100%;
                }
            }
        }
        & ~ .map-wrapper{
          padding-left: 24px;
            @include d(991){
              padding-left: 0;
            }
        }
        @include d(991){
          padding-left: 0;
          margin-top: 30px;
        }
    }
    @include d(991){
      padding: 80px 0 0;
      margin-bottom: 50px;
    }
}

.office{
    &-city{
      color: $blue;
      font-size: 22px;
      @extend .font-title;
      margin-bottom: 8px;
    }
    &-phone,
    &-fax{
      text-transform: uppercase;
      font-size: 20px;
      line-height: 1.25em;
      @extend .font-book;
    }
    &-address{
      @extend .font-book;
      max-width: 202px;
      margin-top: 29px;
      margin-bottom: 19px;
      line-height: 1.25em;
    }
    &-email{
      @extend .font-book;
      display: inline-block;
    }
    &-preview{
      overflow: hidden;
      height: 635px;
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        @include d(1600){
          height: 600px;
        }
        @include d(1440){
          height: 540px;
        }
        @include d(1200){
          height: 450px;
        }
        @include d(991){
          height: 50vw;
          min-height: 380px;
        }
        @include d(767){
          height: 60vw;
        }
        @include d(480){
          height: 70vw;
          min-height: 200px;
        }
    }
    &-content{
      padding: 25px 0
    }
    .link-follow{
      margin-top: 24px;
    }
}