.footer{
  padding: 72px 0 30px;
    &-logo{
      width: 90px;
      margin-left: -5px;
      margin-top: -8px;
      display: inline-block;
    }
    &-nav{
      display: flex;
      justify-content: space-between;
      margin-top: 59px;
      max-width: 560px;
        &-item{
          @include d(480){
            width: 50%;
            margin-bottom: 40px;
          }
        }
        .nav-title{
          margin-bottom: 10px;
        }
        @include d(575){
          flex-wrap: wrap;
        }
    }
    .subscription{
      margin-top: 64px;
        @include d(991){
          margin-top: 55px;
          margin-bottom: 60px;
          max-width: 100%;
        }
    }
    .link{
      font-size: 16px;
      margin-bottom: 8px;
    }
}