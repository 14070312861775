*{
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body{
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  backface-visibility: hidden;
  text-rendering: geometricPrecision;
}

main{
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: normal;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  display: inline-block;
  text-decoration: none;
  color: inherit;
  margin: 0;
  background-color: transparent;
    &:hover{
      text-decoration: none;
      color: inherit;
    }
    &:active,
    &:focus {
      outline: none;
      text-decoration: none;
    }
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: bold;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img{
  max-width: 100%;
  max-height: 100%;
}

picture,
source {
  max-width: 100%;
  display: block;
  border-style: none;
}

picture{
  height: 100%;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
    &:focus{
      outline: none;
    }
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select::-ms-expand {
  display: none;
}

select {
  -webkit-appearance: none;
  appearance: none;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
  resize: none;
  box-shadow: none;
  border-radius: 0;
}

label {
  cursor: pointer;
  display: inline-block;
}

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

p,
li {
  margin: 0;
}

ul,
ol {
  padding-left: 0;
  margin: 0;
}

li{
  list-style-type: none;
}

figure {
  margin: 0;
}

blockquote {
  margin: 0;
}

button {
  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
}

input[type='date'] {
  &::-webkit-inner-spin-button {
    display: none;
  }
  &::-webkit-calendar-picker-indicator {
    opacity: 0;
    position: relative;
    right: -10px;
  }
  &::-webkit-inner-spin-button,
  &::-webkit-calendar-picker-indicator {
    opacity: 0;
    -webkit-appearance: none;
  }
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input{
  box-shadow: none;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}

table{
  border-collapse: collapse;
  width: 100%;
}