.disciplines{
  margin: 134px 0;
    &-box{
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      z-index: 1;
      padding: 50px 0;
      justify-content: center;
      margin-bottom: -150px;
        &-preview{
          height: 500px;
            img{
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            @include m(992){
              display: none;
            }
            @include d(575){
              height: 80vw;
              min-height: 380px;
            }
            @include d(480){
              min-height: 280px;
            }
        }
        &-title{
          margin: 18px 0 16px;
        }
        &-description{
          max-width: 430px;
          margin-bottom: 42px;
            p:not(:last-child){
              margin-bottom: 20px;
            }
            @include d(991){
              max-width: 100%;
            }
        }
        @include d(991){
          min-height: 0;
          margin-bottom: 0;
        }
    }
    &-carousel{
      //position: sticky;
      top: 0;
      right: 0;
      position: absolute;
      width: 1110px;
      overflow: hidden;
        &-list{
          display: flex;
          flex-direction: column-reverse;
          position: relative;
            @include d(1440){
              margin-bottom: 80px;
            }
        }
        &-slide{
          width: 100%;
          height: 100vh;
          position: relative;
          margin-bottom: -150px;
        }
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        svg{
          position: absolute;
          top: 0;
          left: 0;
          height: 100vh;
        }
        @include d(1900){
          width: 55vw;
        }
    }
    &-mobile-carousel{
      @include d(991){
        margin: 0 -50px;
          .disciplines{
            &-box{
              padding: 0 50px;
                &-preview{
                  margin: 0 -50px 40px;
                    @include d(575){
                      margin: 0 -20px 40px;
                    }
                }
                @include d(575){
                  padding: 0 20px;
                }
            }
          }
      }
      @include d(575){
        margin: 0 -20px;
      }
    }
    .carousel-nav-btns{
      position: absolute;
      z-index: 1;
        @include d(991){
          top: 415px;
          right: 0;
        }
        @include d(575){
          top: 65vw;
          right: 30px;
        }
        @include d(480){
          top: 58vw;
        }
        @include m(992){
          display: none;
        }
    }
    @include d(991){
      margin: 50px 0;
    }
    @include d(480){
      margin-bottom: 0;
    }
}