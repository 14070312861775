.post{
  position: relative;
    &-preview{
      overflow: hidden;
      height: 636px;
      position: relative;
      display: block;
        &-box{
          height: 100%;
          width: 100%;
          transition: transform 1s ease;
        }
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          //opacity: 0;
        }
        @include hover(991){
          &:hover{
            .post-preview-box{
              transform: scale(1.2);
            }
          }
        }
        @include d(1440){
          height: 585px;
        }
        @include d(1300){
          height: 500px;
        }
        @include d(1200){
          height: 460px;
        }
        @include d(991){
          height: 50vw;
          min-height: 380px;
        }
        @include d(767){
          height: 65vw;
        }
        @include d(480){
          height: 80vw;
          min-height: 200px;
        }
    }
    &-content{
      padding: 24px 0;
    }
    &-tags{
      display: flex;
        &-item{
          color: $blue;
          text-transform: lowercase;
        }
    }
    &-title{
      margin: 6px 0 11px;
    }
    &-description{
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
        @include d(480){
          -webkit-line-clamp: 5;
        }
    }
}