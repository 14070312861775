.hero{
    &-inner{
      padding: 248px 0 215px;
        &.__default{
          padding-bottom: 82px;
            & + .filters{
              margin-top: -26px;
            }
            & + .project-page{
              .project-page-banner{
                margin-top: -22px;
              }
            }
        }
        @include d(1440){
          padding: 240px 0 160px;
        }
        @include d(1300){
          padding: 215px 0 130px;
        }
        @include d(991){
          padding: 200px 0 70px;
        }
        @include d(480){
          padding: 165px 0 70px;
        }
    }
}