h1{
  font-size: 131px;
  color: $white;
  line-height: .98em;
  @extend .font-title;
    @include d(1700){
      font-size: 120px;
    }
    @include d(1440){
      font-size: 106px;
    }
    @include d(1300){
      font-size: 98px;
    }
    @include d(1200){
      font-size: 88px;
    }
    @include d(991){
      font-size: 84px;
    }
    @include d(767){
      font-size: 74px;
    }
    @include d(575){
      font-size: 64px;
    }
    @include d(480){
      font-size: 46px;
    }
}

h2{
  font-size: 90px;
  @extend .font-title;
  line-height: 1.03em;
  color: $white;
    @include d(1700){
      font-size: 82px;
    }
    @include d(1440){
      font-size: 70px;
    }
    @include d(1300){
      font-size: 64px;
    }
    @include d(1200){
      font-size: 58px;
    }
    @include d(991){
      font-size: 54px;
    }
    @include d(767){
      font-size: 46px;
    }
    @include d(575){
      font-size: 40px;
    }
    @include d(480){
      font-size: 34px;
    }
}

h3{
  font-size: 48px;
  @extend .font-title;
  color: $white;
  line-height: 1.25em;
    @include d(1700){
      font-size: 42px;
    }
    @include d(1440){
      font-size: 36px;
    }
    @include d(767){
      font-size: 30px;
    }
}

h4{
  font-size: 36px;
  @extend .font-title;
  color: $white;
  line-height: 1.25em;
    @include d(1440){
      font-size: 32px;
    }
    @include d(1300){
      font-size: 30px;
    }
    @include d(1200){
      font-size: 28px;
    }
    @include d(991){
      font-size: 26px;
    }
}

h5{
  font-size: 28px;
}

h6{
  font-size: 22px;
  @extend .font-title;
  color: $white;
  line-height: 1.7em;
    @include d(1300){
      font-size: 20px;
    }
    @include d(480){
      font-size: 18px;
    }
}

p{
  font-size: inherit;
  line-height: 1.5em;
}