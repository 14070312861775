.article{
  padding-bottom: 130px;
    &-banner{
      height: 810px;
      overflow: hidden;
      margin: -21px 0 80px;
        &-box{
          height: 100%;
          width: 100%;
        }
        img{
          min-width: 100%;
          min-height: 100%;
          max-height: none;
          object-fit: cover;
        }
        @include d(1700){
          height: 800px;
        }
        @include d(1500){
          height: 750px;
        }
        @include d(1440){
          height: 100vh;
          max-height: 700px;
        }
        @include d(991){
          margin: 0 -50px 50px;
        }
    }
    &-date{
      font-size: 14px;
      @extend .font-title;
      margin-top: 11px;
        @include d(991){
          margin-top: 0;
          margin-bottom: 30px;
        }
    }
    &-description{
      padding-right: 40px;
        .h-4{
          line-height: 1.12em;
        }
    }
    &-body{
      padding: 50px 0 22px;
      line-height: 1.375em;
        p{
          line-height: inherit;
          margin-bottom: 23px;
        }
        img{
          margin: 35px 0 50px;
        }
    }
    &-action{
      display: flex;
      align-items: center;
      justify-content: space-between;
        &-nav{
          .link-follow{
            @extend .h-6;
            text-transform: none;
              &-icon{
                margin-left: 33px;
              }
          }
        }
        &-social{
          display: flex;
          align-items: center;
          justify-content: flex-end;
            .link-social{
              margin-left: 25px;
                @include d(480){
                  margin-right: 25px;
                  margin-left: 0;
                }
            }
            @include d(480){
              justify-content: flex-start;
              margin-top: 30px;
            }
        }
        @include d(480){
          flex-direction: column;
          align-items: flex-start;
        }
    }
}