.value{
  position: relative;
    &-title{
      margin: 17px 0 10px;
        @include d(480){
          position: absolute;
          left: 0;
          top: 0;
          margin: 0;
        }
    }
    &-description{
      font-size: 14px;
      opacity: .5;
        @include d(480){
          margin-top: 15px;
          opacity: .5;
        }
    }
    @include d(480){
      padding-left: 100px;
    }
}