.project{
  &-preview{
    position: relative;
    overflow: hidden;
    display: block;
      &-box{
        height: 100%;
        width: 100%;
        transition: transform 1s ease;
      }
      &:after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,.15);
        pointer-events: none;
      }
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        //opacity: 0;
      }
      @include hover(991){
        &:hover{
          .project-preview-box{
            transform: scale(1.2);
          }
        }
      }
  }
  &-content{
    display: flex;
    justify-content: space-between;
    padding: 15px 0 5px;
      &-title{
        flex: 1;
      }
      &-description{
        flex: 1;
        padding-top: 5px;
      }
      &-action{
        flex: 1;
        display: flex;
        justify-content: flex-end;
        padding-top: 3px;
        max-width: 50px;
      }
      @include d(575){
        flex-direction: column;
      }
  }
  &-tags{
    display: flex;
    flex-wrap: wrap;
    padding: 4px 0;
  }
  &-tag{
    color: $white;
    opacity: .25;
    position: relative;
    padding-left: 15px;
    margin-right: 15px;
    font-size: 14px;
    &:before{
      content: '';
      position: absolute;
      width: 7px;
      height: 7px;
      top: 7px;
      left: 0;
      border-radius: 50%;
      background-color: currentColor;
      pointer-events: none;
    }
  }
  &-action{
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background-color: $blue;
    position: relative;
      svg{
        width: 20px;
        height: 10px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%) rotate(-45deg);
        transition: transform .4s ease;
      }
      path{
        fill: $white;
      }
      @include hover(991){
        &:hover{
          svg{
            transform: translate(-50%,-50%) rotate(0deg);
          }
        }
      }
  }
  &-item{
    .project{
      &-preview{
        height: 490px;
          @include d(991){
            height: 45vw;
            min-height: 340px;
          }
          @include d(767){
            height: 65vw;
          }
          @include d(480){
            min-height: 220px;
          }
      }
    }
  }
  &-accordion{
    margin-bottom: 80px;
      .project{
          &-preview{
            height: 380px;
              @include d(991){
                height: 40vw;
                min-height: 300px;
              }
              @include d(767){
                height: 80vw;
                min-height: 350px;
              }
              @include d(480){
                min-height: 200px;
              }
          }
          &-content{
            @include d(575){
              flex-direction: row;
            }
          }
      }
  }
  &-facts{
    display: flex;
    flex-wrap: wrap;
    padding: 45px 0;
    max-width: 484px;
      &-item{
        width: 50%;
        margin-bottom: 32px;
          .label-uppercase{
            font-size: 12px;
            @extend .font-title;
          }
      }
  }
  &-fact{
      &-icon{
        width: 31px;
        height: 31px;
        margin-bottom: 15px;
          img{
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: left center;
          }
      }
      &-value{
        margin-top: 7px;
        @extend .font-heavy;
        line-height: 1.26em;
        max-width: 210px;
          li{
            position: relative;
            padding-left: 15px;
              &:not(:last-child){
                margin-bottom: 5px;
              }
              &:before{
                content: '';
                position: absolute;
                top: 7px;
                left: 0;
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background-color: currentColor;
                pointer-events: none;
              }
          }
      }
      &-description{
        padding-top: 42px;
      }
  }
}
