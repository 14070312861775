.projects{
  padding: 69px 0;
    &-carousel{
      min-height: 690px;
        &-slide{
          overflow: hidden;
          position: relative;
            &:focus{
              outline: none;
            }
            img{
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
            .project{
                &-preview{
                  height: 597px;
                    img{
                      opacity: 1;
                    }
                    @include d(1300){
                      height: 500px;
                    }
                    @include d(991){
                      height: 100vh;
                      max-height: 700px;
                    }
                    @include d(767){
                      height: 90vw;
                      min-height: 500px;
                    }
                    @include d(575){
                      min-height: 380px;
                    }
                }
                &-description{
                  @include m(576){
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                  }
                }
                &-content{
                    &-title{
                      max-width: 55%;
                        @include d(575){
                          max-width: 100%;
                        }
                    }
                    &-description{
                      max-width: 45%;
                        @include d(575){
                          max-width: 100%;
                        }
                    }
                    @include d(991){
                      padding: 15px 50px 5px;
                    }
                    @include d(575){
                      padding: 15px 20px 5px;
                    }
                }
            }
        }
        @include d(1300){
          min-height: 580px;
        }
        @include d(991){
          margin: 0 -50px;
          min-height: 0;
        }
        @include d(575){
          margin: 0 -20px;
        }
    }
    @include d(767){
      padding: 50px 0;
    }
}