.news{
  margin-bottom: 30px;
    &-categories{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
        .link{
          font-size: 16px;
          text-transform: capitalize;
          margin: 0 45px;
            &:nth-child(1){
              margin-left: 0;
            }
            &:last-child{
              margin-right: 0;
            }
            @include d(1440){
              margin: 0 30px;
            }
            @include d(1200){
              margin: 0 20px;
            }
        }
        @include d(991){
          min-height: 58px;
          justify-content: space-between;
        }
    }
    &-search{
      display: flex;
      align-items: center;
      height: 100%;
        .input{
          padding-left: 36px;
            &-container{
              margin-bottom: 0;
              width: 100%;
            }
        }
    }
    &-list{
      display: flex;
      flex-wrap: wrap;
      margin: 0 -73px;
      padding-top: 92px;
      padding-bottom: 20px;
        &-box{
          padding: 0 73px;
          margin-bottom: 90px;
          width: 50%;
            &:nth-child(3n){
              width: 100%;
                .post{
                    &-preview{
                      height: 817px;
                        &-box{
                          &:before{
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            bottom: 0;
                            right: 0;
                            background-color: rgba(0,0,0,.1);
                            pointer-events: none;
                          }
                        }
                        @include d(1700){
                          height: 800px;
                        }
                        @include d(1440){
                          height: 100vh;
                        }
                    }
                    &-content{
                      position: absolute;
                      bottom: 50px;
                      left: 101px;
                        @include d(1200){
                          bottom: 30px;
                          left: 85px;
                        }
                        @include d(480){
                          bottom: 10px;
                          left: 45px;
                          padding-right: 20px;
                        }
                    }
                    &-description{
                      display: none;
                    }
                    &-tags{
                        &-item{
                          color: $white;
                        }
                    }
                }
            }
            @include d(1440){
              padding: 0 30px;
            }
            @include d(767){
              width: 100%;
              padding: 0;
            }
            @include d(480){
              margin-bottom: 50px;
            }
        }
        @include d(1440){
          margin: 0 -30px;
        }
        @include d(767){
          margin: 0;
        }
    }
    &-other{
      .news{
        &-list{
          padding-top: 58px;
        }
      }
    }
}