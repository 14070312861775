.feedback{
  padding-bottom: 100px;
    &-form{
      padding: 80px 0;
        .link-btn{
          max-width: 140px;
          width: 100%;
        }
        @include d(1600){
          padding: 15px 0;
        }
        @include d(991){
          padding: 50px 0;
        }
    }
    &-banner{
      position: absolute;
      top: 0;
      left: 0;
      max-width: 953px;
      width: 50vw;
      overflow: hidden;
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        @include d(1500){
          height: 600px;
        }
        @include d(991){
          position: relative;
          max-width: 100%;
          width: 100%;
          height: 60vw;
          min-height: 400px;
        }
    }
    @include d(991){
      padding-bottom: 50px;
    }
}