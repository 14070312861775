.services {
  &-switcher {
    padding: 48px 0;
  }
  &-carousel {
    padding: 43px 0 0 95px;
    max-width: 970px;
      &-wrap{
          position: relative;
            .carousel-nav-pagination{
              @include m(992){
                position: absolute;
                top: 30px;
                left: 7px;
              }
              @include d(991){
                justify-content: flex-start;
              }
            }
            .carousel-nav-btns{
              @include m(992){
                position: absolute;
                top: 41px;
                right: 0;
              }
            }
      }
      &-slide {
        padding-bottom: 50px;
      }
      @include d(1440){
        max-width: 80%;
      }
      @include d(991){
        max-width: 100%;
        padding: 30px 0 0 0;
      }
  }
  &-title {
    text-transform: uppercase;
  }
  &-description {
    padding: 6px 0 0 30px;
    column-count: 2;
      li{
        margin-bottom: 5px;
        display: inline-block;
        width: 100%;
      }
      @include d(991){
        padding: 6px 0 0;
      }
  }
  &-tabs{
    margin: 80px -5px 0;
    padding: 0 30px;
      &-item{

      }
      .slick{
        &-slide{
          transition: opacity .4s ease;
          opacity: 0;
        }
        &-active{
          opacity: 1;
        }
      }
      @include d(1440){
        margin: 50px -90px 0;
      }
      @include d(1300){
        margin: 50px -100px 0;
      }
      @include d(1200){
        margin: 50px 0 0;
      }
      @include d(991){
        display: none;
      }
  }
  &-tab{
    height: 170px;
    //width: 195px;
    padding: 0 49px;
    margin: 0 auto;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
      &-title{
        font-size: 14px;
        text-transform: uppercase;
        @extend .font-title;
        margin: 0 -26px;
        position: relative;
          @include d(1600){
            font-size: 12px;
          }
          @include d(1200){
            font-size: 11px;
          }
      }
      &-numb{
        font-size: 14px;
        color: $blue;
        @extend .font-title;
        transition: opacity .4s ease, color .4s ease;
      }
      &-plus{
        position: absolute;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        border: 1px $white solid;
        top: 6px;
        right: 46px;
        transition: opacity .4s ease;
        opacity: 0;
          &:before,
          &:after{
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            background-color: $white;
          }
          &:before{
            width: 1px;
            height: 18px;
          }
          &:after{
            width: 18px;
            height: 1px;
          }
          @include d(1600){
            top: 12px;
            right: 40px;
          }
      }
      .hexagon{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        pointer-events: none;
        transition: opacity .4s ease;
          path{
            fill: none;
            stroke: $white;
          }
          &.active{
            opacity: 0;
              path{
                fill: $blue;
                stroke: none;
              }
          }
      }
      @include hover(991){
        &:hover{
          .hexagon{
            opacity: 0;
            &.active{
              opacity: 1;
            }
          }
          .services-tab{
            &-numb{
              color: $white;
              opacity: .5;
            }
            &-plus{
              opacity: 1;
            }
          }
        }
      }
      @include d(1200){
        width: 200px;
      }
  }
}