.map{
  width: 1177px;
  //height: 1111px;
  height: auto;
  margin: 38px auto 0;
  position: relative;
    &-offices{
      position: absolute;
      top: 73%;
      left: 80%;
        &-list{
          margin-top: 16px;
            li{
              margin-bottom: 4px;
              position: relative;
              transition: color .4s ease, padding-left .4s ease;
              cursor: pointer;
              white-space: nowrap;
                &:before{
                  content: '';
                  position: absolute;
                  width: 7px;
                  height: 7px;
                  border-radius: 50%;
                  background-color: $blue;
                  top: 7px;
                  left: 0;
                  opacity: 0;
                  transform: translateX(-5px);
                  transition: opacity .4s ease, transform .4s ease;
                  pointer-events: none;
                }
                p{
                  font-size: 14px;
                  max-width: 192px;
                  white-space: normal;
                  display: none;
                  margin-top: 10px;
                    &:last-child{
                      margin-bottom: 15px;
                    }
                    @include d(480){
                      font-size: 12px;
                    }
                }
                &.active,
                &:hover{
                  color: $blue;
                  padding-left: 15px;
                    &:before{
                      opacity: 1;
                      transform: translate(0);
                    }
                    p{
                      display: block;
                    }
                }
            }
        }
        &-btn{
          @include d(480){
            font-size: 12px;
          }
        }
        @include d(991){
          left: 75%;
        }
        @include d(767){
          left: 70%;
        }
        @include d(480){
          left: 60%;
        }
    }
    &-tabs{
      display: flex;
      align-items: center;
      margin: 78px 0 92px;
        &-item{
          @extend .font-title;
          flex: 1;
          position: relative;
          font-size: 22px;
          text-transform: uppercase;
          color: #525455;
          padding: 15px 0;
          text-align: center;
          transition: color .4s ease;
          cursor: pointer;
            &:before,
            &:after{
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              height: 1px;
              transition: width .4s ease;
              pointer-events: none;
            }
            &:before{
              background-color: #525455;
              width: 100%;
            }
            &:after{
              width: 0;
              background-color: $white;
            }
            @include hover(991){
              &:hover{
                color: $white;
              }
            }
            &.is-active{
              color: $white;
                &:after{
                  width: 100%;
                }
            }
            @include d(767){
              font-size: 18px;
            }
            @include d(575){
              font-size: 16px;
            }
            @include d(480){
              font-size: 14px;
            }
        }
        @include d(767){
          margin: 50px 0;
        }
        @include d(575){
          margin: 30px 0;
        }
    }
    &.united_kingdom{
      width: 933px;
      height: auto;
        .country{
          stroke-width: 1px;
        }
        .map{
          &-offices{
            left: -120px;
            top: 0;
              @include d(1440){
                left: 0;
              }
              @include d(767){
                left: 30px;
              }
              @include d(575){
                left: 20px;
                max-width: 150px;
              }
          }
        }
        svg{
          @include d(575){
            margin-top: 50px;
          }
        }
        @include d(1440){
          width: 100%;
        }
        @include d(767){
          margin: 0 -30px;
          width: calc(100% + 60px);
        }
        @include d(575){
          margin: 0 -20px;
          width: calc(100% + 40px);
        }
    }
    @include d(1600){
      width: 1120px;
      //height: 1062px;
    }
    @include d(1440){
      width: 1000px;
      //height: 950px;
    }
    @include d(1300){
      width: 920px;
      //height: 870px;
    }
    @include d(1200){
      width: 760px;
      //height: 740px;
    }
    @include d(991){
      width: 100%;
      height: auto;
    }
    @include d(767){
      width: 100vw;
      height: auto;
      margin: 0 -50px;
    }
    @include d(575){
      margin: 0 -20px;
    }
}

.country{
  fill: transparent;
  stroke: $white;
  stroke-width: 3px;
  transition: all .4s ease;
    &-title{
      pointer-events: none;
        path{
          fill: $white
        }
    }
    &.place{
      fill: rgba(255,255,255,.25);
      cursor: pointer;
        //&:hover{
        //  fill: $blue;
        //}
    }
    &.active{
      fill: $blue;
    }
}