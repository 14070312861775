.header{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 48px 0;
  z-index: 3;
  transition: padding 1s ease, background-color 1s ease;
    &-logo{
      display: inline-block;
      width: 90px;
      margin-top: -12px;
      position: relative;
      z-index: 1;
    }
    &-nav{
      padding-top: 14px;
      padding-left: 150px;
      padding-right: 93px;
      display: flex;
      align-items: center;
      justify-content: space-between;
        &-search{
          position: relative;
          top: 3px;
        }
        @include d(1440){
          padding-left: 40px;
          padding-top: 15px;
          padding-right: 30px;
        }
        @include d(1300){
          padding-left: 0;
          padding-right: 0;
        }
        @include d(991){
          justify-content: flex-end;
        }
    }
    &-cta{
      text-align: right;
        .link-btn{
          height: 48px;
          line-height: 48px;
        }
    }
    &.collapsed{
      padding: 15px 0 0;
      background-color: $black;
    }
}