.modal{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: none;
    &-box{
      width: 100%;
      height: 100%;
        &.__done{
          background-color: rgba(25,27,29,.95);
            .modal{
                &-content{
                  max-width: 670px;
                  pointer-events: none;
                }
            }
        }
        &.__search{
          background-color: $black;
            .modal{
                &-content{
                  max-width: 1198px;
                  align-items: flex-start;
                }
                &-close{
                    &-btn{
                      left: auto;
                      right: 22px;
                      top: 22px;
                      background-color: transparent;
                      opacity: 1;
                        svg{
                          width: 30px;
                          height: 30px;
                            line{
                              stroke: $white;
                            }
                            @include d(480){
                              width: 20px;
                              height: 20px;
                            }
                        }
                        @include hover(991){
                          &:hover{
                            svg{
                              transform: rotate(180deg);
                                line{
                                  stroke: $blue;
                                }
                            }
                          }
                        }
                        @include d(480){
                          right: 0;
                        }
                    }
                }
            }
        }
    }
    &-close{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
        &-btn{
          position: absolute;
          left: 0;
          right: 0;
          width: 69px;
          height: 69px;
          border-radius: 50%;
          background-color: $white;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          opacity: 0;
          z-index: 1;
            svg{
              width: 20px;
              height: 20px;
              fill: none;
              transition: transform .4s ease;
                line{
                  stroke: $black;
                  transition: all .4s ease;
                    @include d(767){
                      stroke: $white;
                    }
                }
            }
            @include d(767){
              background-color: transparent;
              top: 10px !important;
              right: 10px;
              left: auto !important;
              opacity: 1;
            }
        }
    }
    &-content{
      text-align: center;
      margin: 0 auto;
      height: 100%;
      padding: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      opacity: 0;
      position: relative;
        &-title{
          margin-bottom: 38px;
          pointer-events: none;
            @include d(575){
              padding: 0 10px;
            }
        }
        &-subtitle{
          pointer-events: none;
        }
        &-form{
          width: 100%;
          margin-top: 100px;
            @include d(1440){
              margin-top: 40px;
            }
        }
    }
}