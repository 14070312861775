.contact {
  padding: 77px 0 18px;
    &-box {
        margin-top: 44px;
        margin-bottom: 40px;
    }
    .office{
      max-width: 300px;
        @include d(575){
          max-width: 100%;
        }
    }
}