.filters{
  z-index: 1;
    &-list{
      display: flex;
      justify-content: space-between;
      border-top: 1px rgba(255,255,255,.15) solid;
      border-bottom: 1px rgba(255,255,255,.15) solid;
      min-height: 115px;
        &-search{
          flex: 1;
          max-width: 332px;
            @include d(1300){
              max-width: 280px;
            }
            @include d(1200){
              max-width: 250px;
            }
            @include d(991){
              max-width: 50%;
              padding: 10px 0;
            }
        }
        &-categories{
          flex: 1;
          border-left: 1px rgba(255,255,255,.15) solid;
          border-right: 1px rgba(255,255,255,.15) solid;
            @include d(991){
              flex: none;
              order: 2;
              width: 100%;
              border-left: none;
              border-right: none;
              border-top: 1px rgba(255,255,255,.15) solid;
              border-bottom: 1px rgba(255,255,255,.15) solid;
            }
        }
        &-sort{
          flex: 1;
          max-width: 372px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding-left: 10px;
            @include d(1300){
              max-width: 280px;
            }
            @include d(1200){
              max-width: 250px;
            }
            @include d(991){
              max-width: 50%;
              padding: 10px 0;
            }
        }
        @include d(1200){
          min-height: 90px;
        }
        @include d(991){
          flex-wrap: wrap;
          border: none;
        }
    }
}