.team{
  padding: 62px 0;
    &-list{
      display: flex;
      flex-wrap: wrap;
      margin: 0 -49px;
      padding-top: 58px;
        &-box{
          padding: 0 49px;
          width: 33.33%;
          margin-bottom: 77px;
            @include d(1300){
              padding: 0 30px;
            }
            @include d(991){
              width: 50%;
            }
            @include d(767){
              padding: 0 10px;
            }
        }
        @include d(1300){
          margin: 0 -30px;
        }
        @include d(767){
          margin: 0 -10px;
        }
    }
    @include d(480){
      padding-bottom: 0;
    }
}

.person{
  &-photo{
    height: 402px;
    overflow: hidden;
    margin-bottom: 12px;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0;
      }
      @include d(1440){
        height: 350px;
      }
      @include d(991){
        height: 50vw;
        min-height: 340px;
      }
      @include d(767){
        height: 50vw;
        min-height: 280px;
      }
      @include d(575){
        height: 60vw;
      }
      @include d(480){
        min-height: 200px;
      }
  }
  &-name{
    margin-bottom: 6px;
  }
  &-position{
    opacity: .25;
    font-size: 14px;
  }
}