.projects{
    &-sort{
      display: flex;
      align-items: center;
      padding: 14px 0;
      position: relative;
      z-index: 1;
        .select-custom{
          @include d(575){
            max-width: 100%;
            margin-bottom: 10px;
          }
        }
        @include d(575){
          flex-direction: column;
        }
    }
    &-list{
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px;
      padding: 43px 0;
        &-box{
          padding: 0 10px;
          width: 50%;
          margin-bottom: 46px;
            @include d(767){
              width: 100%;
              padding: 0;
            }
        }
        @include d(767){
          margin: 0;
        }
    }
}