body{
  background-color: $black;
  color: $white;
  font-size: 16px;
  @extend .font-medium;
}

#wrapper{
  position: relative;
  overflow: hidden;
  min-height: 100vh;
}

.page{
  min-height: 100vh;
    &-not-found{
      & ~ .footer{
        display: none;
      }
    }
}

.container{
  max-width: 1470px;
    @include d(1700){
      width: 90%;
    }
    @include d(991){
      width: 100%;
      padding: 0 50px;
    }
    @include d(575){
      padding: 0 20px;
    }
}

.section{
  position: relative;
}

.link{
  font-size: 18px;
  @extend .font-medium;
  text-transform: lowercase;
  line-height: 1em;
  border: none;
  background-color: transparent;
  padding: 0;
  color: inherit;
  display: inline-flex;
  align-items: center;
  transition: color .4s ease, opacity .4s ease;
    @include hover(991){
      &:hover{
        opacity: .5;
      }
    }
    &.active{
      opacity: .5;
    }
    &-icon{
      width: 14px;
      height: 14px;
      margin-right: 12px;
    }
    &-btn{
      font-size: 14px;
      @extend .font-medium;
      color: $white;
      background-color: $blue;
      border-radius: 50px;
      border: none;
      height: 45px;
      line-height: 45px;
      padding: 0 35px;
      text-transform: capitalize;
      transition: background-color .4s ease, color .4s ease;
      white-space: nowrap;
        @include hover(991){
          &:hover{
            background-color: $white;
            color: $blue;
          }
        }
        &-white{
          background-color: $white;
          color: $blue;
            @include hover(991){
              &:hover{
                background-color: $blue;
                color: $white;
              }
            }
        }
        &-arrow{
          width: 69px;
          height: 69px;
          border-radius: 50%;
          background-color: $white;
          position: relative;
          border: none;
          padding: 0;
            &:after{
              content: '';
              position: absolute;
              width: 26px;
              height: 15px;
              top: 50%;
              left: 50%;
              transform: translate(-50%,-50%);
              background-repeat: no-repeat;
              background-size: contain;
              background-position: center;
              background-image: url(../../images/icons/arrow-right.svg);
              pointer-events: none;
              transition: opacity .4s ease;
            }
            &.__prev{
              &:after{
                transform: translate(-50%,-50%) scaleX(-1);
              }
            }
            &.disabled{
              pointer-events: none;
                &:after{
                  opacity: .33;
                }
            }
            @include d(1200){
              width: 58px;
              height: 58px;
            }
        }
    }
    &-follow{
      padding: 0;
      border: none;
      background-color: transparent;
      color: $blue;
      text-transform: uppercase;
      @extend .font-heavy;
      font-size: 14px;
      line-height: 1.26em;
      display: inline-flex;
      align-items: center;
      transition: color .4s ease;
        &-icon{
          width: 26px;
          height: 15px;
          margin-left: 12px;
          transition: transform .4s ease;
          fill: currentColor;
        }
        @include hover(991){
          &:hover{
            color: $white;
              .link-follow-icon{
                transform: translateX(10px);
              }
          }
        }
    }
    &-social{
      width: 17px;
      height: 17px;
      background-repeat: no-repeat;
      background-size: 17px;
      background-position: center;
      transition: opacity .4s ease;
        &[aria-label="pinterest"]{
          background-image: url(../../images/icons/pinterest.svg);
        }
        &[aria-label="facebook"]{
          background-image: url(../../images/icons/facebook.svg);
        }
        &[aria-label="twitter"]{
          background-image: url(../../images/icons/twitter.svg);
        }
        &[aria-label="linkedin"]{
          background-image: url(../../images/icons/linkedin.svg);
        }
       @include hover(991){
         &:hover{
           opacity: .5;
         }
       }
    }
    &-back{
      font-size: 12px;
      text-transform: uppercase;
      @extend .font-title-medium;
      display: inline-flex;
      align-items: center;
      line-height: 1em;
      transition: color .4s ease, opacity .4s ease;
        svg{
          margin-right: 11px;
        }
        path{
          fill: currentColor;
        }
        @include hover(991){
          &:hover{
            color: $blue;
          }
        }
    }
    @include d(1200){
      font-size: 16px;
    }
}

.label{
  color: $grey;
  text-transform: capitalize;
  font-size: 14px;
  line-height: 1.25em;
    &-uppercase{
      font-size: 16px;
      @extend .font-heavy;
      line-height: 1em;
      text-transform: uppercase;
      color: $white;
        @include d(1200){
          font-size: 14px;
          letter-spacing: .05em;
        }
        @include d(480){
          font-size: 12px;
        }
    }
}

.follow{
  display: flex;
  justify-content: flex-end;
  max-width: 636px;
  margin-top: -23px;
  position: relative;
  z-index: 1;
    &-inner{
      margin-top: 78px;
      margin-left: 36px;
      justify-content: flex-start;
        @include d(1440){
          padding-top: 50px;
        }
        @include d(991){
          margin-left: 0;
        }
    }
    &-link{
      padding: 0;
      border: none;
      border-radius: 50%;
      width: 113px;
      height: 113px;
      background-color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      overflow: hidden;
        &:before,
        &:after{
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background-color: $black;
          transition: transform .4s ease;
          transform: scale(0);
          transition-delay: .05s;
        }
        &:after{
          background-color: $blue;
          transition-delay: 0s;
        }
        svg{
          width: 25px;
          height: 26px;
          position: relative;
          z-index: 1;
            path, rect{
              fill: $blue;
              transition: fill .4s ease;
            }
            @include d(767){
              width: 20px;
              height: 20px;
            }
        }
        @include hover(991){
          &:hover{
            background-color: transparent;
            transition-delay: .4s;
              &:before{
                transform: scale(1);
                transition-delay: 0s;
              }
              &:after{
                transform: scale(1);
                transition-delay: .1s;
              }
              svg{
                path, rect{
                  fill: $white;
                }
              }
          }
        }
        @include d(1440){
          width: 100px;
          height: 100px;
        }
        @include d(767){
          width: 75px;
          height: 75px;
        }
    }
    @include d(1500){
      margin-top: 0;
    }
    @include d(480){
      padding-right: 50px;
    }
}

.paragraph{
    &.__error{
      margin: 10px 0;
      font-size: 14px;
      color: $red;
    }
    p{
      margin-bottom: 24px;
    }
    .link-btn{
      margin-top: 13px;
    }
}

.copyright{
  margin-top: 70px;
    &-title{
      @extend .font-heavy;
      font-size: 12px;
    }
}

.route-mask{
  position: fixed;
  top: 100%;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: $black;
  z-index: -3;
}

.foot{
    &-banner{
      height: 794px;
      overflow: hidden;
      position: relative;
        img{
          min-width: 100%;
          min-height: 100%;
          max-height: none;
          object-fit: cover;
        }
        @include d(1800){
          height: 700px;
        }
        @include d(1500){
          height: 100vh;
          max-height: 700px;
        }
        @include d(767){
          max-height: 640px;
        }
    }
}

.preloader{
  height: 794px;
}

.detail{
  padding-bottom: 80px;
    &-values{
      display: flex;
      margin: 72px -45px 0;
      position: relative;
      padding-top: 70px;
        &:before{
          content: '';
          position: absolute;
          top: 0;
          left: 45px;
          right: 45px;
          height: 1px;
          background-color: $white;
          pointer-events: none;
            @include d(480){
              left: 0;
              right: 0;
            }
        }
        &-box{
          width: 25%;
          margin: 0 45px;
            @include d(991){
              margin: 0 30px 50px;
              width: calc(50% - 60px);
            }
            @include d(480){
              width: 100%;
              margin: 0 0 50px;
            }
        }
        @include d(991){
          flex-wrap: wrap;
          margin: 70px -30px 0;
        }
        @include d(480){
          margin: 70px 0 0;
        }
    }
    @include d(480){
      padding-bottom: 0;
    }
}

.hr{
  border: none;
  height: 1px;
  background-color: $white;
}

.c-scrollbar{
  display: none;
}

.burger{
  display: inline-block;
  padding: 10px;
  margin: -10px -10px -10px 40px;
    &-box{
      width: 20px;
      height: 14px;
      position: relative;
        &-arrow{
          position: absolute;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: $white;
          transition: all .4s ease;
            &:nth-child(1){
              top: 0;
            }
            &:nth-child(2){
              top: 50%;
              margin-top: -1px;
            }
            &:nth-child(3){
              bottom: 0;
            }
        }
    }
    &.active{
      .burger-box{
        &-arrow{
          &:nth-child(1){
            transform: rotate(-135deg);
            top: 6px;
          }
          &:nth-child(2){
            width: 0;
          }
          &:nth-child(3){
            transform: rotate(135deg);
            bottom: 6px;
          }
        }
      }
    }
    @include m(992){
      display: none;
    }
}

.split{
  &-text{
    display: flex;
    flex-wrap: wrap;
      &-word{
        &:not(:last-child){
          margin-right: 10px;
        }
      }
  }
}

.office-title{
  text-transform: capitalize;
}

.back{
  padding: 53px 0;
}