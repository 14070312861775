.clients{
  padding: 22px 0;
    &-overlay{
      width: 100%;
      height: 423px;
      position: relative;
        @include d(1300){
          height: 384px;
        }
        @include d(575){
          height: 234px;
        }
        @include d(480){
          height: 264px;
        }
    }
    &-list{
      position: absolute;
      left: 0;
      top: 0;
      white-space: nowrap;
      padding-top: 54px;
      //animation: moving infinite linear;
      //animation-fill-mode: forwards;
      //animation-direction: alternate-reverse;
      //animation-duration: 50s;
        &-item{
          display: inline-block;
          width: 369px;
          height: 369px;
          border-radius: 50%;
          border: 1px rgba(255,255,255,.1) solid;
          line-height: 396px;
          text-align: center;
          margin: 0 -15px;
            img{
              pointer-events: none;
              max-width: 160px;
              max-height: 80px;
                @include d(480){
                  max-width: 110px;
                  max-height: 40px;
                }
            }
            @include d(1300){
              width: 330px;
              height: 330px;
              line-height: 338px;
            }
            @include d(575){
              width: 280px;
              height: 280px;
              line-height: 288px;
            }
            @include d(480){
              width: 210px;
              height: 210px;
              line-height: 218px;
            }
        }
    }
}

//@keyframes moving {
//  0% {
//    transform: translateX(0);
//  }
//  100% {
//    transform: translateX(-100%);
//  }
//}