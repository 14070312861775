.input{
  width: 100%;
  background-color: transparent;
  border: 1px $grey-100 solid;
  height: 45px;
  font-size: 16px;
  color: $white;
  @extend .font-title-regular;
  padding: 0 20px;
  transition: border-color .4s ease;
    &-container{
      position: relative;
      margin-bottom: 36px;
        &-border{
          margin-bottom: 0;
            .input{
              border: none;
              border-bottom: 1px rgba(255,255,255,.25) solid;
              padding: 0;
              @extend .font-heavy;
                &-border{
                  position: absolute;
                }
            }
        }
        &-check{
          position: relative;
          display: inline-block;
          cursor: pointer;
            @include hover(991){
              &:hover{
                opacity: 1;
                  .input-check-title{
                    color: $blue;
                  }
              }
            }
        }
        &.__search{
            .input{
              font-size: 18px;
              height: 64px;
              @extend .font-medium;
              padding-right: 110px;
                @include d(480){
                  font-size: 16px;
                }
            }
            .link-follow{
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: 0;
              color: $white;
            }
        }
        &.error{
          .input{
            border-color: $red;
              &-border{
                background-color: $red;
              }
          }
        }
    }
    &-border{
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 1px;
      background-color: $white;
      transition: width .4s ease, background-color .4s ease;
        &.active{
          width: 100%;
        }
    }
    &-error{
      color: $red;
      @extend .font-book;
      font-size: 12px;
      position: absolute;
      bottom: -20px;
      left: 0;
    }
    &-label{
      display: inline-block;
      margin-bottom: 14px;
    }
    &-mark{
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      font-size: 22px;
      opacity: .5;
      color: $white;
      text-transform: lowercase;
      pointer-events: none;
      transition: font-size .4s ease, transform .4s ease, top .4s ease;
        &.active{
          transform: translateY(0);
          font-size: 18px;
          top: -15px;
            @include d(480){
              font-size: 12px;
            }
        }
        @include d(480){
          font-size: 16px;
        }
    }
    &-search{
        &-btn{
          border: none;
          padding: 0;
          background-color: transparent;
          position: absolute;
          top: 50%;
          left: -7px;
          transform: translateY(-50%);
          width: 30px;
          height: 30px;
          background-repeat: no-repeat;
          background-size: 18px;
          background-position: center;
          background-image: url(../../../assets/images/icons/loupe-blue.svg);
        }
    }
    &-check{
      position: absolute;
      pointer-events: none;
      opacity: 0;
        &:checked{
            & ~ .input-check-title{
              color: $blue;
            }
        }
        &-title{
          cursor: pointer;
          transition: color .4s ease;
        }
    }
    &.search{
        &-news{
          border: none;
        }
    }
    &:focus{
      & ~ .input-mark{
        transform: translateY(0);
        font-size: 18px;
        top: -15px;
          @include d(480){
            font-size: 12px;
          }
      }
    }
    &::-webkit-input-placeholder {
      color: $white;
    }
    &::-moz-placeholder {
      color: $white;
    }
    &:-ms-input-placeholder {
      color: $white;
    }
    &:-moz-placeholder {
      color: $white;
    }
    &:focus{
      & ~ .input-border{
        width: 100%;
      }
    }
}

.textarea{
  @extend .input;
  height: 147px;
  padding: 15px 20px;
}

.select{
    &-custom{
      max-width: 272px;
      width: 100%;
        &.focused{
          z-index: 3;
        }
    }
}