.split-title{
  overflow: hidden;
}

.scale{
    img{
      opacity: 0;
    }
}

.fade{
  opacity: 0;
    &-on-scroll{
      opacity: 0;
      transform: translateY(100px);
    }
}

.group{
    &-scale,
    &-fade{
      opacity: 0;
    }
}