.testimonials{
  padding: 118px 0;
    &-carousel{
      padding-top: 55px;
        &-slide{
          &:focus{
            outline: none;
          }
        }
        @include d(767){
          padding-top: 0;
        }
    }
    .carousel-nav-btns{
      justify-content: flex-end;
      margin-top: -33px;
      position: relative;
      z-index: 1;
        @include d(767){
          margin-top: 0;
          margin-bottom: -85px;
        }
        @include d(480){
          margin-bottom: -73px;
        }
    }
    .label{
      @include d(767){
        margin-bottom: 50px;
      }
    }
    @include d(1300){
      padding: 80px 0;
    }
}

.testimonial{
  display: flex;
  justify-content: space-between;
    &-author{
      width: 220px;
      padding-top: 15px;
        &-photo{
          width: 106px;
          height: 106px;
          border-radius: 50%;
          position: relative;
          margin-left: 90px;
            img{
              border-radius: 50%;
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
            &:before{
              content: '';
              position: absolute;
              left: -85px;
              top: 0;
              width: 100%;
              height: 100%;
              border-radius: 50%;
              background-color: $blue;
              background-repeat: no-repeat;
              background-size: 30px;
              background-position: center;
              background-image: url(../../../images/icons/dots.svg);
              pointer-events: none;
                @include d(1440){
                  left: -65px;
                }
                @include d(480){
                  background-size: 20px;
                  left: -45px;
                }
            }
            @include d(1440){
              width: 84px;
              height: 84px;
              margin-left: 65px;
            }
            @include d(480){
              width: 58px;
              height: 58px;
              margin-left: 45px;
            }
        }
        &-title{
          margin-top: 72px;
          padding-right: 200px;
            @include d(480){
              padding-right: 100px;
            }
        }
        &-position{
          font-size: 14px;
          opacity: .25;
          margin-top: 5px;
          padding-right: 200px;
        }
        &-logo{
          width: 145px;
          position: absolute;
          bottom: 5px;
          right: 0;
            @include d(480){
              width: 100px;
              bottom: 10px;
            }
        }
        @include d(767){
          margin-bottom: 30px;
        }
    }
    &-body{
      position: relative;
      max-width: 832px;
      padding-right: 10px;
        .h-4{
          line-height: 1.12em;
        }
        @include d(1440){
          max-width: 75%;
        }
        @include d(991){
          max-width: 70%;
        }
        @include d(767){
          max-width: 100%;
        }
    }
    @include d(767){
      flex-direction: column;
    }
}